@keyframes animar {
  0% {
    background: #ff8080;
  }
  25% {
    background: #ffffff;
  }
  50% {
    background: #ff8080;
  }
  100% {
    background: #ffffff;
  }
}

.active {
  animation-name: animar;
  animation-duration: 5s;
  animation-iteration-count: 5s;
}

.linkCustom {
  text-decoration: none;
  color: #0f284c;
  margin-left: 5px;
}

.linkCustom:visited {
  color: #ff8080;
}
